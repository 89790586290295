import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {ContactInterface} from "interfaces/common";
import {
    PassengerInfoInterface,
    PassengerInterface,
} from "interfaces/passenger";
import {RootState} from "app/store";

interface PassengerState {
    model: PassengerInterface;
    childErrorMessage: string;
    infantErrorMessage: string;
}

const initialState: PassengerState = {
    model: {
        adults: [],
        children: [],
        infants: [],
        contact: {
            countryPrefix: "",
            emailAddress: "",
            phoneNumber: "",
            phoneType: "mobile",
            setHideFileKey: false,
            conditions: false,
        },
    },
    childErrorMessage: "",
    infantErrorMessage: "",
};

export const passengerSlice = createSlice({
    name: "passenger",
    initialState,
    reducers: {
        setPaxModel: (state, action) => {
            state.model = action.payload;
        },
        setPassengerModel: (
            state,
            action: PayloadAction<{
                payload: Partial<PassengerInfoInterface>;
                paxType: string;
                index: number;
            }>,
        ) => {
            const paxType = action.payload.paxType;
            const index = action.payload.index;
            const firstName = action.payload.payload.firstName
                ? action.payload.payload.firstName
                : state.model[paxType][index].firstName;
            const lastName = action.payload.payload.lastName
                ? action.payload.payload.lastName
                : state.model[paxType][index].lastName;

            state.model[paxType][index] = {
                ...state.model[paxType][index],
                ...action.payload.payload,
                fullName: `${firstName.trim()} ${lastName.trim()}`
            };
        },
        setPassengerContact: (
            state,
            action: PayloadAction<Partial<ContactInterface>>,
        ) => {
            state.model.contact = {...state.model.contact, ...action.payload};
        },
        setChildErrorMessage: (state, action: PayloadAction<string>) => {
            state.childErrorMessage = action.payload;
        },
        setInfantErrorMessage: (state, action: PayloadAction<string>) => {
            state.infantErrorMessage = action.payload;
        },
        deleteFqtv: (state) => {
            state.model.adults = state.model.adults.map((adult) => {
                return {
                    ...adult,
                    fqtvNumber: "",
                    fqtvProgram: "",
                }
            });
            state.model.children = state.model.children.map((child) => {
                return {
                    ...child,
                    fqtvNumber: "",
                    fqtvProgram: "",
                }
            });
        },
        resetPaxs: (state) => {
            state.model = initialState.model;
            state.childErrorMessage = initialState.childErrorMessage;
            state.infantErrorMessage = initialState.infantErrorMessage;
        },
    },
});

export const {
    setPaxModel,
    setPassengerModel,
    setPassengerContact,
    setChildErrorMessage,
    setInfantErrorMessage,
    resetPaxs,
    deleteFqtv,
} = passengerSlice.actions;

export const selectPassengerModel = (state: RootState) => state.passenger.model;
export const selectChildErrorMessage = (state: RootState) =>
    state.passenger.childErrorMessage;
export const selectInfantErrorMessage = (state: RootState) =>
    state.passenger.infantErrorMessage;

export default passengerSlice.reducer;
